import React from "react";
import "./FooterComponent.css";

function FooterComponent() {
  return (
    <div className="footer">
        <p>Copyright &copy; 2020. All rights reserved. | AEC Software solutions</p>
        <ul className="footer-nav">
            <li><a href="https://www.facebook.com/egyptaec" className="fa fa-facebook"><span>Facebook</span></a></li>
            <li><a href="https://www.linkedin.com/company/aecegypt" className="fa fa-linkedin"><span>Linkedin</span></a></li>
        </ul>
    </div>
  );    
}

export default FooterComponent;
