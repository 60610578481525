import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Form, Input, Button } from "antd";
import { sendEmail } from "../../services/EmailService";
import "./ContactUsPage.css";

function ContactUs() {
  const [formData, setFormData] = useState<any>({});
  const { addToast } = useToasts();
  const [form] = Form.useForm();

  const inputOnChange=(key: string,value:string)=>{
    formData[key]=value;
    setFormData(formData);
  };
  
  const onFinish = (values: any) => {
    sendEmail({
      template: "contact-us",
      firstName: values.firstName,
      lastName: values.lastName,
      titleAndCompany: values.titleAndCompany,
      email: values.email,
      phone: values.phone,
      fullMessage: values.fullMessage,
    })
      .then((response) => {
        addToast("Message sent Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        form.resetFields();
        setFormData({});
      })
      .catch((error) => {
        addToast(error.message, { appearance: "error", autoDismiss: true });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="contact">
      <div className="contact-form-section">
        <div className="container">
          <div className="contact-form-section-form flex-column">
            <Form
              form={form}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <h1>Get in touch with us</h1>
              <div className="flex-row">
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                  ]}
                >
                  <Input
                    className="contact-input"
                    placeholder={`First name`}
                    value={formData.firstName}
                    allowClear
                    onChange={(e: any) => {
                      inputOnChange("firstName", e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  rules={[
                    { required: true, message: "Please input your Last Name!" },
                  ]}
                >
                  <Input
                    className="contact-input"
                    placeholder={`Last name`}
                    value={formData.lastName}
                    allowClear
                    onChange={(e: any) => {
                      inputOnChange("lastName", e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="flex-row">
                <Form.Item
                  name="titleAndCompany"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Title/Company!",
                    },
                  ]}
                >
                  <Input
                    className="contact-input"
                    placeholder={`Title/Company`}
                    value={formData.titleAndCompany}
                    allowClear
                    onChange={(e: any) => {
                      inputOnChange("titleAndCompany", e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="flex-row">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    className="contact-input"
                    placeholder={`Email`}
                    value={formData.email}
                    allowClear
                    onChange={(e: any) => {
                      inputOnChange("email", e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="flex-row">
                <Form.Item
                  name="phone"
                  rules={[
                    { required: true, message: "Please input your Phone!" },
                  ]}
                >
                  <Input
                    className="contact-input"
                    placeholder={`Phone number`}
                    value={formData.phone}
                    allowClear
                    onChange={(e: any) => {
                      inputOnChange("phone", e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="flex-row">
                <Form.Item
                  name="fullMessage"
                  rules={[
                    { required: true, message: "Please input your Message!" },
                  ]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 5, maxRows: 10 }}
                    className="contact-input"
                    value={formData.fullMessage}
                    placeholder={`Message`}
                    onChange={(e: any) => {
                      inputOnChange("fullMessage", e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="flex-row">
                <Form.Item>
                  <Button
                    className="contact-form-submit"
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="locations-section">
        <div className="container">
          <div className="locations-section-heading">
            <h2 className="align-center">Office locations</h2>
          </div>
          <div className="locations-section-row flex-row">
            <div className="locations-section-column">
              <img className="locations-section-column-icon" src="/images/egypt-flag.png" height="30" width="30" alt="" />
              <h3>Head office</h3>

              <p> 167 Emtedad Ramsis 2 Apt.32, Nasr City, Cairo</p>

              <p>
                Tel: <a href="tel:+20220816755">+(202) 20816755</a> /
                <a href="tel:+20220816483">+(202) 20816483</a>
              </p>
            </div>
            <div className="locations-section-column">
              <img className="locations-section-column-icon" src="/images/egypt-flag.png" height="30" width="30" alt="" />
              <h3>Operational branch</h3>

              <p> 1180 Zahraa Nasr City Apt.13, Cairo</p>

              <p>
                Tel:<a href="tel:+20224127491">+(202) 24127491</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
