import React from "react";
import "./HeaderComponent.css";
import "../topmenu/topmenu.component";
import TopMenu from "../topmenu/topmenu.component";

function HeaderComponent() {
  return (
    <div id="header-wrapper">
      <div className="side-buttons">
          <ul className="header-nav">
            <li style={{marginRight:20}}>
              <a href="http://mail.aecegypt.org/" className="fa fa-envelope">
                <span>AEC Web mail</span>
              </a>
            </li>
            <li>
              <a href="http://portal.aecegypt.org/" className="fa fa-briefcase">
                <span>AEC Portal</span>
              </a>
            </li>
          </ul>
        </div>
      <div id="header" className="container">
        <div id="logo">
          <img src="/images/logo.png" height="110" width="150" alt="" />
          <h1>
            <a href="/">Arab Company for Engineering & Systems Consultations</a>
          </h1>
        </div>
        <TopMenu></TopMenu>
      </div>
    </div>
  );
}

export default HeaderComponent;
