import React from "react";
import "./VisionAndMissionPage.css";

function VisionAndMissionPage() {
  return (
    <div className="vision">
      <div className="container">
        <div className="section">
          <h2>AEC's Vision</h2>
          <p>
            Arab Company for engineering and systems consultations (AEC) is a
            consulting non-profit organization for the dissemination of thought,
            culture and knowledge of quality. It?s a distinctive center for
            excellence in consultancy, establishing management systems,
            encouraging scientific researches particularly in the quality field
            and generally the varied management systems. AEC used to manage
            these systems to comply with international, regional standards and
            public service society in Egypt, Arab countries and the Middle-East
            specifications. AEC also aims to be the first consultancy firm in
            Egypt, Arab countries.
          </p>
        </div>
        <div className="section">
          <h2>AEC's mission</h2>
          <p>
            AEC is a leading company in preparing, designing and developing
            programs in different management areas, especially in the quality
            management field. Which are achieved according to the latest
            international standards, regional and local communities to spread
            the concept of quality through local and international boards such
            as seminars, lectures, courses and projects assigned to the company
            or contracted by AEC. AEC pass through different business sectors to
            emphasis quality concepts, whether it was industrial or service
            sectors. Different branches e.g. educational, health, engineering,
            pharmaceutical industries, food industries, aviation services,
            maritime service, heavy or light industries, in private and public
            ?governmental ?sectors.
          </p>
        </div>
        <div className="section">
          <h2>AEC's quality policy</h2>
          <p>
            The Arab Company provide consultancy services and training in the
            areas of different systems at the highest level in accordance with
            the global systems, to achieve customer requirements and
            satisfaction through the establishing a quality management systems
            conform to the specifications of international standard (ISO
            9001/2008) , The company makes sure policy suitability through the
            periodic review and as well as commitment to development and
            continual improvement of activities and services of the company
            through the development of measurable objectives that will be
            reviewed continuously.{" "}
          </p>
        </div>
        <div className="section no-border">
          <h2>Strategic objectives of the AEC Company</h2>
          <p></p>
          <ul>
            <li>
              <div>
                <span className="text">
                  Develop programs and materials dissemination systems,
                  awareness of management systems in general and&nbsp; quality
                  in particular for the disseminating awareness purpose of
                  quality culture and management, to suit the needs of society
                  and ensure lasting excellence.
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="text">
                  Provision of experts and consultants and the level of
                  scientific expertise and skills required to achieve the
                  knowledge and awareness of adequate and necessary for the
                  establishment of systems, methods and distinctive sound while
                  providing the appropriate environment to achieve this.
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="text">
                  Provide a suitable environment for comprehensive documented
                  policies, regulations, laws and guidelines governing the
                  various relationships to create these systems.
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="text">
                  Adopt policies that are clear and specific on how to achieve
                  quality performance and continuous improvement and that this
                  policy produces evidence of action for each department
                  investigating how to measure and compare the performance
                  criteria specified prior.
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="text">
                  To achieve customer satisfaction through the creation of
                  distinct systems, which is trapped and even check their
                  expectations of them?
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="text">
                  Increased quality awareness of community participation and
                  environmental development among consultants and experts and
                  customers.
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="text">
                  Improve the performance of consultants and experts.
                </span>
              </div>
            </li>
            <li>
              <div>
                <span className="text">
                  Developing AEC own resources to ensure the continuity of its
                  mission.
                </span>
              </div>
            </li>
          </ul>
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default VisionAndMissionPage;
