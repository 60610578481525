import React from "react";
import "./MediaPage.css";
import ImageGallery from "react-image-gallery";

const Media = () => {
  const images = [
    {
      original: "https://imgur.com/qqVClAi.png",
      thumbnail: "https://imgur.com/qqVClAit.png",
    },
    {
      original: "https://imgur.com/tk9sRlv.png",
      thumbnail: "https://imgur.com/tk9sRlvt.png",
    },
  ];

  return (
    <div className="media">
      <div className="container">
        <ImageGallery items={images} lazyLoad thumbnailPosition={"left"} />
      </div>
    </div>
  );
};

export default Media;
