import React from "react";
import "./TrainingPage.css";

function TrainingPage() {
  return (
    <div className="training">
      <div className="container">
        <div className="section no-border">
          <h2>A.E.C. performs the following training programs</h2>
          <p></p>
          <ul>
            <li className="text">
              Basic concept of international standard (ISO 9001/2008
              -ISO14000/2004 ? ISO 13485/2003 -BSOHSAS18001/2007- ISO17799
              -ISO/IEC17025/2005, HACCP, ISO 15189/2007, ISO 26000, ISO22000,
              and ISO/IEC17011).
            </li>
            <li className="text">
              Internal Audits courses (Quality management system, Environmental
              management system, Health Safety management System, Information
              security management system) and QMS for labs.
            </li>
            <li className="text">HACCP.</li>
            <li className="text">Statistical Techniques (SPC).</li>
            <li className="text">Marketing planning &amp; Research.</li>
            <li className="text"> Production planning.</li>
            <li className="text"> TQM/EFQM.</li>
            <li className="text"> Environmental feasibility study.</li>
            <li className="text"> Logistics.</li>
            <li className="text"> Environmental Engineering.</li>
            <li className="text"> Human Resources.</li>
            <li className="text"> Computer courses (software, hardware).</li>
            <li className="text"> Lead assessor course.</li>
            <li className="text"> Calibration.</li>
            <li className="text"> Measurements (electrical, mechanical).</li>
            <li className="text"> Testing &amp; Inspection.</li>
            <li className="text">
              How the world manages projects by Primavera.
            </li>
            <li className="text">
              We can design and teach any client desired training program.
            </li>
            <li className="text"> Six Sigma.</li>
            <li className="text"> Quality self control (QSC).</li>
            <li className="text"> Technical courses.</li>
            <li>
              <span className="text">
                Any tailored courses upon the request of customers
              </span>
            </li>
          </ul>
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default TrainingPage;
