import React from "react";
import "./ArticlePreviewComponent.css";
import { Link } from "react-router-dom";

const ArticlePreviewComponent= (props: any) => {
  return (
    <div className="article-preview flex-row">
      <div className="art-pre-image">
        <img src={props.image} width="100%" {...(props.stretchImage?{height:"100%"}:'')} alt="" />
      </div>
      <div className="art-pre-content flex-column">
        <div className="art-pre-text">
          <div className="art-pre-title">
              {props.titleLink?
              <h2><a href={props.titleLink}>{props.title}</a></h2>
              :
              <h2>{props.title}</h2>
              }
            
          </div>
          <p className="art-pre-paragraph">
            {props.summary}
          </p>
        </div>
        <div className="art-pre-button">
            {props.button ?
            <Link className="button" to={'/article/'+props.articleId}>
              {props.button}
            </Link>:''
          }
        </div>
      </div>
    </div>
  );
}

export default ArticlePreviewComponent;
