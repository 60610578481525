import React from "react";
import "./LinksPage.css";

function LinksPage() {
  return (
    <div className="links">
      <div className="container">
        <div className="section no-border">
          <h2>Useful Links</h2>
          <p></p>
          <ol>
            <li>
              ESQ: <a href="http://www.esq.org.eg/">www.esq.org.eg</a>
            </li>
            <li>
              IRCA: <a href="http://www.irca.org/">www.irca.org</a>
            </li>

            <li>
              ISO: <a href="http://www.iso.org/">www.iso.org</a>
            </li>

            <li>
              EGAC: <a href="http://www.egac.gov.eg/">www.egac.gov.eg</a>
            </li>

            <li>
              IEF: <a href="http://www.ief-ngo.org/">www.ief-ngo.org</a>
            </li>

            <li>
              ILAC: <a href="http://www.ilac.org/">www.ilac.org</a>
            </li>

            <li>
              A2LA: <a href="http://www.a2la.org/">www.a2la.org</a>
            </li>

            <li>
              IMC: <a href="http://www.imc-egypt.org/">www.imc-egypt.org</a>
            </li>

            <li>
              BSI: <a href="http://www.bsi.com/">www.bsi.com</a>
            </li>

            <li>
              PharmAssure:
              <a href="http://www.pharmassure.com.au/">
                www.pharmassure.com.au
              </a>
            </li>

            <li>
              IAF: <a href="http://www.iaf.nu/">www.iaf.nu</a>
            </li>

            <li>
              NIS: <a href="http://www.nis.sci.eg/">www.nis.sci.eg</a>
            </li>

            <li>
              NISS: <a href="http://www.niss.org/">www.niss.org</a>
            </li>

            <li>
              SASO:
              <a href="http://www.saso.org.sa/site">www.saso.org.sa/site</a>/
            </li>

            <li>
              EOS: <a href="http://www.eos.org.eg/">www.eos.org.eg</a>
            </li>
          </ol>
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default LinksPage;
